import React from "react";
import {
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Collapse,
} from "@material-ui/core";
import { useWatch, Controller } from "react-hook-form";

import FormGroup from "./FormGroup";
import ImageSlider from "./ImageSlider";

function GiftCustomize({
  control,
  defaultValues,
  addressDescription,
  register,
}) {
  const is_gift = useWatch({
    control,
    name: "is_gift",
    defaultValue: defaultValues?.is_gift,
  });

  const add_chocolate = useWatch({
    control,
    name: "add_chocolate",
    defaultValue: defaultValues?.add_chocolate,
  });

  return (
    <FormGroup>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControlLabel
            label="Is this a gift?"
            control={
              <Controller
                name="is_gift"
                control={control}
                render={(props) => (
                  <Switch
                    {...props}
                    color="primary"
                    checked={is_gift}
                    onChange={(event) => {
                      props.onChange(event.target.checked);
                    }}
                  />
                )}
              />
            }
          />
        </Grid>
      </Grid>

      <Collapse in={is_gift}>
        <Grid container justify="center" spacing={1}>
          <Grid item>
            <ImageSlider
              defaultValues={defaultValues}
              control={control}
              addressDescription={addressDescription}
              register={register}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              name="sender_name"
              label="Sender name"
              control={control}
              rules={{ required: is_gift }}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              name="gift_note"
              label="Gift Note"
              control={control}
              rules={{ required: is_gift }}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Do you want to add chocolate? (+$X)"
              control={
                <Controller
                  name="add_chocolate"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={add_chocolate}
                      onChange={(event) => {
                        props.onChange(event.target.checked);
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Grid>
      </Collapse>
    </FormGroup>
  );
}

export default GiftCustomize;
