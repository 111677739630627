import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Button, Typography } from "@material-ui/core";
import { useWatch } from "react-hook-form";

import GiftTagPreview from "./GiftTagPreview";

export default function ImageSlider({
  control,
  defaultValues,
  addressDescription,
  register,
}) {
  const from = "John Fromme";
  const gift_note = useWatch({
    control,
    name: "gift_note",
    defaultValue: defaultValues?.gift_note,
  });
  const add_chocolate = useWatch({
    control,
    name: "add_chocolate",
    defaultValue: defaultValues?.add_chocolate,
  });
  const recipient_name = useWatch({
    control,
    name: "recipient_name",
    defaultValue: defaultValues?.recipient_name,
  });

  const recipientText = recipient_name
    ? `${recipient_name}, ${addressDescription}`
    : addressDescription;

  const { images } = useStaticQuery(
    graphql`
      query BackgroundImages {
        images: allFile(filter: { relativeDirectory: { eq: "backgrounds" } }) {
          totalCount
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                original {
                  height
                  width
                }
              }
            }
          }
        }
      }
    `
  );

  const offset = images.edges.findIndex(
    (el) => el.node.name === defaultValues.background_image
  );
  const [index, setIndex] = useState(offset);

  const { totalCount: length } = images;
  const handleNext = () =>
    index === length - 1 ? setIndex(0) : setIndex(index + 1);
  const handlePrev = () =>
    index === 0 ? setIndex(length - 1) : setIndex(index - 1);

  const { node } = images.edges[index];

  return (
    <>
      <Typography variant="subtitle2">Gift background Images</Typography>
      <Button onClick={() => handlePrev()}>Back</Button>
      <Button onClick={() => handleNext()}>Next</Button>
      <GiftTagPreview
        from={from}
        giftNote={gift_note}
        addChocolate={add_chocolate}
        recipientText={recipientText}
        fluid={node.childImageSharp.fluid}
      />
      <input
        type="hidden"
        ref={register}
        name="background_image"
        value={node.name}
      />
    </>
  );
}
