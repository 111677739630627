import React from "react";
import { navigate } from "gatsby";
import { Link } from "gatsby-theme-material-ui";
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { isEmpty, cloneDeep } from "lodash";

import AddressForm from "../components/AddressForm";
import FormGroup from "../components/FormGroup";
import GiftCustomize from "../components/GiftCustomize";
import OrderLayout from "../components/OrderLayout";
import { updateLocalState, blankState } from "../utils/kits";

const useStyles = makeStyles((theme) => ({
  recipientName: {
    marginBottom: theme.spacing(1),
  },
  back: {
    textAlign: "right",
  },
}));

const extractAddress = (address) => {
  const terms = address.terms.map((item) => item["value"]);
  return {
    state: terms.slice(-2, -1),
    city: terms.slice(-3, -2),
    line1: terms.slice(0, -3).join(" "),
  };
};

let renderCount = 0;
const Customize = () => {
  const classes = useStyles();
  const { action, state } = useStateMachine(updateLocalState);
  const defaultValues = cloneDeep(state.data);

  const { control, errors, handleSubmit, reset, register } = useForm({
    defaultValues,
  });

  console.log("renderCount", ++renderCount);

  const onSubmit = (data) => {
    action(data);
    console.log("submitted data", data);
    navigate("/checkout");
  };

  const structured_address = extractAddress(state.data.recipient_address);

  return (
    <OrderLayout paddingTop="5vh">
      <Grid container spacing={3}>
        {!isEmpty(errors) && (
          <FormGroup label="Errors">
            <Grid item xs={12}>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
            </Grid>
          </FormGroup>
        )}
        <Grid item xs={12} className={classes.back}>
          <Link to="/">Back</Link>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <FormGroup label="Recipient">
            <Grid item xs={12}>
              <Controller
                as={TextField}
                name="recipient_name"
                label="Recipient Name"
                control={control}
                className={classes.recipientName}
                variant="outlined"
                fullWidth
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressForm
                control={control}
                disabled
                address={structured_address}
              />
            </Grid>
          </FormGroup>

          <GiftCustomize
            defaultValues={defaultValues}
            control={control}
            addressDescription={state?.data?.recipient_address?.description}
            register={register}
          />

          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={() => {
                reset(blankState);
                action({ reset: true });
                navigate("/");
              }}
            >
              Reset
            </Button>
            <Button color="secondary" type="submit">
              Checkout
            </Button>
          </Grid>
        </Grid>
      </form>
    </OrderLayout>
  );
};

export default Customize;
