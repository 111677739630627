import React from "react";
import { Collapse, Fade, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "gatsby-background-image";

const textBackground = {
  backgroundColor: "rgba(255,255,255,0.55)",
  width: "85%",
  minHeight: "1.7em",
  marginLeft: "7.5%",
  paddingTop: "0.1em",
  paddingBottom: "0.1em",
  textAlign: "center",
  fontFamily: "Open Sans",
};

const useStyles = makeStyles((theme) => ({
  imageContainer: (props) => ({
    width: 500,
    height: 300,
    whiteSpace: "pre-line",
    position: "relative",
  }),
  noteContent: {
    ...textBackground,
    marginTop: "4em",
    maxHeight: "9.5em",
    overflow: "hidden",
    fontSize: "1em",
    fontFamily: "cursive",
  },
  noteFrom: {
    ...textBackground,
    position: "relative",
    top: "1em",
    fontSize: "1em",
  },
  noteChocolate: {
    ...textBackground,
    fontSize: "1em",
    position: "absolute",
    bottom: "3.5em",
  },
  noteRecipient: {
    ...textBackground,
    fontSize: "1em",
    fontWeight: "bold",
    position: "absolute",
    bottom: "1em",
  },
}));

export default function GiftTagPreview({
  from,
  giftNote,
  addChocolate,
  recipientText,
  fluid,
}) {
  const classes = useStyles();
  return (
    <Fade in={true} mountOnEnter unMountOnExit>
      <BackgroundImage fluid={fluid}>
        <div className={classes.imageContainer}>
          <Typography className={classes.noteFrom}>From: {from}</Typography>
          <Typography className={classes.noteContent}>{giftNote}</Typography>
          <Collapse in={addChocolate}>
            <Typography className={classes.noteChocolate}>
              A HomeClix chocolate set will arrive separately
            </Typography>
          </Collapse>
          <Typography className={classes.noteRecipient}>
            {recipientText}
          </Typography>
        </div>
      </BackgroundImage>
    </Fade>
  );
}
