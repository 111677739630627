import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
}));

export default function AddressForm({ control, address, disabled }) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {disabled ? (
          <TextField
            label="Address"
            value={address.line1}
            className={classes.textField}
            variant="outlined"
            fullWidth
            disabled
          />
        ) : (
          <Controller
            as={TextField}
            name="line1"
            label="Address"
            className={classes.textField}
            control={control}
            defaultValue={address.line1}
            rules={{ required: true }}
            variant="outlined"
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={8}>
        {disabled ? (
          <TextField
            label="City"
            value={address.city}
            className={classes.textField}
            variant="outlined"
            fullWidth
            disabled
          />
        ) : (
          <h1>Placeholder</h1>
        )}
      </Grid>
      <Grid item xs={4}>
        {disabled ? (
          <TextField
            label="State"
            value={address.state}
            className={classes.textField}
            variant="outlined"
            fullWidth
            disabled
          />
        ) : (
          <h1>Placeholder</h1>
        )}
      </Grid>
    </Grid>
  );
}
